import React, { useState, useEffect } from 'react';
import Login from './Login';
import UserManagement from './UserManagement';
import EnvironmentManagement from './EnvironmentManagement'; // Import the component
import axios from "axios";
import {apiUrl} from "../../config";
import {setAuthToken} from "../../api";

const Admin = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState("userManagement"); // Initialize currentTab state

    const validateToken = async (token: string) => {
        try {
            const response = await axios.post(`${apiUrl}/admin/validateToken`, { token });
            return response.data.valid;
        } catch (error) {
            console.error('Error validating token:', error);
            return false;
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            validateToken(token).then(valid => {
                if (valid) {
                    setAuthToken(token);
                }
                setAuthenticated(valid);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mt-5">
            {!authenticated ? (
                <Login setAuthenticated={setAuthenticated} />
            ) : (
                <div className="">
                    <header className="d-flex justify-content-center mb-3">
                        <button
                            className={`btn mr-2 btn-${currentTab === "userManagement" ? "primary" : "secondary"} blue-button me-3`}
                            onClick={() => setCurrentTab("userManagement")}
                        >
                            User Management
                        </button>
                        <button
                            className={`btn ml-2 btn-${currentTab === "environmentManagement" ? "primary" : "secondary"}`}
                            onClick={() => setCurrentTab("environmentManagement")}
                        >
                            Environment Management
                        </button>
                    </header>
                    {currentTab === "userManagement" && <UserManagement />}
                    {currentTab === "environmentManagement" && <EnvironmentManagement />}
                </div>
            )}
        </div>
    );
};

export default Admin;
