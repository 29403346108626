// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Lorin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Lorin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lorin', sans-serif;
}

.header {
  background-color: #1f12b0;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-bottom: 13px;
}

.logo {
  width: 40px;
  height: auto;
  margin-right: 20px;
}

.app-container {
  padding: 45px;
  height: calc(100vh - 70px);

}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card {
  border-radius: 10px; /* Adjust border radius as needed */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adjust box shadow as needed */
  width: 600px; /* Adjust width as needed */
  padding: 20px;
  margin-bottom: 100px;
}

.card h2 {
  margin-top: 0;
  margin-bottom: 20px;
}
.card p {
  text-align: center;
}


.btn-primary {
  background-color: #2136e3 !important;
  border-color: #2136e3 !important;
}

.btn-secondary {
  background-color: #94949e !important;
  border-color: #94949e !important;
}
`, "",{"version":3,"sources":["webpack://./src/media/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,+DAA0C;AAC5C;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,0BAA0B;;AAE5B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB,EAAE,mCAAmC;EACxD,2CAA2C,EAAE,gCAAgC;EAC7E,YAAY,EAAE,2BAA2B;EACzC,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;;;AAGA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;AAClC","sourcesContent":["@font-face {\n  font-family: \"Lorin\";\n  src: url(\"./Lorin.ttf\") format(\"truetype\");\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Lorin', sans-serif;\n}\n\n.header {\n  background-color: #1f12b0;\n  color: white;\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  padding-bottom: 13px;\n}\n\n.logo {\n  width: 40px;\n  height: auto;\n  margin-right: 20px;\n}\n\n.app-container {\n  padding: 45px;\n  height: calc(100vh - 70px);\n\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.card {\n  border-radius: 10px; /* Adjust border radius as needed */\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adjust box shadow as needed */\n  width: 600px; /* Adjust width as needed */\n  padding: 20px;\n  margin-bottom: 100px;\n}\n\n.card h2 {\n  margin-top: 0;\n  margin-bottom: 20px;\n}\n.card p {\n  text-align: center;\n}\n\n\n.btn-primary {\n  background-color: #2136e3 !important;\n  border-color: #2136e3 !important;\n}\n\n.btn-secondary {\n  background-color: #94949e !important;\n  border-color: #94949e !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
