import React, { useState, useEffect } from 'react';
import { apiUrl } from '../config';
import DropdownWithCustom, {OptionType} from '../components/dropdown.component';
import api from '../api';
import {Environment} from "./admin/EnvironmentManagement";

const UserLogin: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [environment, setEnvironment] = useState<OptionType>();
    const [environments, setEnvironments] = useState<OptionType[]>([]);
    const [showEnvironmentDropdown, setShowEnvironmentDropdown] = useState(false);
    const [isAuthenticatedWithMultiplyEnvironments, setIsAuthenticatedWithMultiplyEnvironments] = useState<Environment[] | null>(null)

    useEffect(() => {
        const checkToken = async () => {
            try {
                let url = `${apiUrl}/v1/auth/token-is-valid`;

                const response = await api.get(url, { withCredentials: true });
                const {environments} = response.data;

                if (response.status === 200 && environments) {
                   if (environments.length === 1) {
                       window.location.href = environments[0].url
                   } else if (environments.length > 1) {
                       setIsAuthenticatedWithMultiplyEnvironments(environments)
                   } else {
                       setError('You have not access to any env')
                   }
                }
            } catch (error) {
                console.error('Error checking token:', error);
            }
        };

        checkToken();
    }, []);

    const handleNext = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            if (!email || !password) {
                setError('Email and password are required.');
                return;
            }
            setError('');

            const response = await api.post(`${apiUrl}/admin/environments`, { email, password }, { withCredentials: true });
            const { environments } = response.data
            if (response.status === 201 && environments) {

                if (environments.length === 1) {
                    const response = await api.post(`${apiUrl}/v1/auth/login`,
                        { email, password, environment: environments[0].name },
                        { withCredentials: true }
                    );

                    if (response.status === 201 && response.data.redirectUrl) {
                        window.location.href = response.data.redirectUrl;
                    }

                } else {
                    const environmentOptions = response.data.environments.map((env: any) => ({
                        label: env.name,
                        value: env.url
                    }));
                    setEnvironments(environmentOptions)
                    setShowEnvironmentDropdown(true);
                }
            }
        } catch (error: any) {
            console.error('Error fetching environments:', error);
            setError(error?.response?.data?.message || 'Unexpected network error')
        }
    };

    const handleSelectEnvirionment = (value: OptionType | OptionType[]) => {
        setEnvironment(value as OptionType)
    }

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!environment) {
                setError('Choose environment')
                return
            }
            setError('')

            const response = await api.post(`${apiUrl}/v1/auth/login`, { email, password, environment: environment?.label }, { withCredentials: true });

            if (response.status === 201 && response.data.redirectUrl) {
                window.location.href = response.data.redirectUrl;
            }

        } catch (error) {
            setError('Invalid email or password');
        }
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">Login </h2>
                    {!isAuthenticatedWithMultiplyEnvironments && <form onSubmit={showEnvironmentDropdown ? handleLogin : handleNext}>
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        {showEnvironmentDropdown ? (
                            <>
                                <DropdownWithCustom
                                    label="Environment"
                                    options={environments}
                                    multiply={false}
                                    onSelectionChange={handleSelectEnvirionment}
                                    selectedValues={[]}
                                />
                                <button type="submit" className="btn btn-primary btn-block mt-3">Login</button>
                            </>
                        ) : (
                            <button type="submit" className="btn btn-primary btn-block">Next</button>
                        )}
                    </form>}
                    {isAuthenticatedWithMultiplyEnvironments && <div>
                        <p>You have access to:</p>
                        {isAuthenticatedWithMultiplyEnvironments.map((env: any) =>  <button type="button" className="btn btn-primary btn-block" onClick={() => window.location.href = env.url}>Go to {env.name}</button>)}
                    </div> }
                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default UserLogin;
