import React, { useState, useEffect } from 'react';
import { apiUrl } from '../config';
import api from '../api';

interface Props {
    showModal: boolean;
    closeModal: () => void;
    editingEnvironment: Environment | null;
    fetchEnvironments: () => void;
}

interface Environment {
    _id?: string;
    name: string;
    url: string;
}

const EditCreateEnvironmentModal: React.FC<Props> = ({
                                                         showModal,
                                                         closeModal,
                                                         editingEnvironment,
                                                         fetchEnvironments,
                                                     }) => {
    const [newEnvironment, setNewEnvironment] = useState<Environment>({
        name: '',
        url: '',
    });

    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (editingEnvironment) {
            setNewEnvironment({ ...editingEnvironment });
        } else {
            setNewEnvironment({
                name: '',
                url: '',
            });
        }
    }, [editingEnvironment]);

    const saveEnvironment = async () => {
        try {
            if (!newEnvironment.name || !newEnvironment.url) {
                setError('Name and URL are required.');
                return;
            }
            setError('');
            if (editingEnvironment) {
                await api.put(`${apiUrl}/admin/environments/${editingEnvironment._id}`, newEnvironment);
            } else {
                await api.post(`${apiUrl}/admin/environments`, newEnvironment);
            }
            fetchEnvironments();
            closeModal();
        } catch (error) {
            console.error('Error saving environment:', error);
            setError('Failed to save environment. Network error.');
        }
    };


    return (
        <>
            {showModal && (
                <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{editingEnvironment ? 'Edit Environment' : 'Create Environment'}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Name"
                                    value={newEnvironment.name}
                                    onChange={(e) => setNewEnvironment({ ...newEnvironment, name: e.target.value })}
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="URL"
                                    value={newEnvironment.url}
                                    onChange={(e) => setNewEnvironment({ ...newEnvironment, url: e.target.value })}
                                />
                                {error && <div className="text-danger">{error}</div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                    Close
                                </button>
                                <button type="button" className="btn btn-primary" onClick={saveEnvironment}>
                                    {editingEnvironment ? 'Save' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditCreateEnvironmentModal;
