import React, { useState, useEffect } from 'react';
import {apiUrl} from "../../config";
import EditCreateUserModal from "../../modals/EditCreateUserModal";
import api from "../../api";


interface User {
    _id: string;
    email: string;
    fullName: string;
    linkedinUrl: string;
    environments: { name: string, url: string }[];
    numberLogins: number;
    sessionCount: number;
}

const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editingUser, setEditingUser] = useState<User | null>(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await api.get<User[]>(`${apiUrl}/admin/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const deleteUser = async (userId: string) => {
        try {
            await api.delete(`${apiUrl}/admin/users/${userId}`);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const openModalForEdit = (user: User) => {
        setEditingUser(user);
        setShowModal(true);
    };

    const openModalForCreate = () => {
        setEditingUser(null);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="">
                <button className="btn btn-primary mb-3" onClick={openModalForCreate}>Create User</button>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Email</th>
                        <th>Full Name</th>
                        <th>Linkedin URL</th>
                        <th>Environment</th>
                        <th>№ Logins</th>
                        <th>№ Session</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <td>{user.email}</td>
                            <td>{user.fullName}</td>
                            <td>{user.linkedinUrl}</td>
                            <td>{user.environments.map(e => e.name)?.join(', ')}</td>
                            <td>{user.numberLogins}</td>
                            <td>{user.sessionCount}</td>
                            <td>
                                <button className="btn btn-sm btn-primary mr-2" onClick={() => openModalForEdit(user)}>Edit</button>
                                <button className="btn btn-sm btn-danger" onClick={() => deleteUser(user._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <EditCreateUserModal
                    showModal={showModal}
                    closeModal={closeModal}
                    editingUser={editingUser}
                    fetchUsers={fetchUsers}
                />
        </div>
    );
};

export default UserManagement;
