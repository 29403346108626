import React, {useState} from 'react';
import Select from 'react-select';

export type OptionType = {
    value: string;
    label: string;
};

interface DropdownWithCustomProps {
    label: string;
    options: OptionType[];
    multiply?: boolean
    onSelectionChange: (selectedValues: OptionType[] | OptionType) => void;
    selectedValues: OptionType[];
}

const DropdownWithCustom: React.FC<DropdownWithCustomProps> = ({ label, options, multiply, onSelectionChange, selectedValues }) => {
    const [selected, setSelected] = useState(selectedValues)

    const handleChange = (values: any) => {
        setSelected(values)
        onSelectionChange(values)
    }


    return (
        <div className="form-group">
            <label>{label}</label>
            <Select
                options={options as any}
                onChange={handleChange}
                isMulti={!!multiply as any}
                value={selected as any}
            />
        </div>
    );
};

export default DropdownWithCustom;
