import axios from 'axios';
import {apiUrl} from "./config";

const api = axios.create({
    baseURL: apiUrl
});

export const setAuthToken = (token: string | null) => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

export default api;
