import React, { useState, useEffect } from 'react';
import api from "../../api";
import EditCreateEnvironmentModal from "../../modals/EditCreateEnvironmentModal";
import {apiUrl} from "../../config";

export interface Environment {
    _id: string;
    name: string;
    url: string;
}

const EnvironmentManagement: React.FC = () => {
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editingEnvironment, setEditingEnvironment] = useState<Environment | null>(null);

    useEffect(() => {
        fetchEnvironments();
    }, []);

    const fetchEnvironments = async () => {
        try {
            const response = await api.get<Environment[]>(`${apiUrl}/admin/environments/list`);
            setEnvironments(response.data);
        } catch (error) {
            console.error('Error fetching environments:', error);
        }
    };

    const deleteEnvironment = async (environmentId: string) => {
        try {
            await api.delete(`${apiUrl}/admin/environments/${environmentId}`);
            fetchEnvironments();
        } catch (error) {
            console.error('Error deleting environment:', error);
        }
    };

    const openModalForEdit = (environment: Environment) => {
        setEditingEnvironment(environment);
        setShowModal(true);
    };

    const openModalForCreate = () => {
        setEditingEnvironment(null);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="">
                <button className="btn btn-primary mb-3" onClick={openModalForCreate}>
                    Create Environment
                </button>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>URL</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {environments?.length > 0 && environments.map(environment => (
                        <tr key={environment._id}>
                            <td>{environment.name}</td>
                            <td>{environment.url}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-primary mr-2"
                                    onClick={() => openModalForEdit(environment)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => deleteEnvironment(environment._id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <EditCreateEnvironmentModal
                    showModal={showModal}
                    closeModal={closeModal}
                    editingEnvironment={editingEnvironment}
                    fetchEnvironments={fetchEnvironments}
                />
        </div>
    );
};

export default EnvironmentManagement;
