import React, { useState, useEffect } from 'react';
import { apiUrl } from '../config';
import DropdownWithCustom, { OptionType } from '../components/dropdown.component';
import api from '../api';
import { Environment } from '../views/admin/EnvironmentManagement';

interface Props {
    showModal: boolean;
    closeModal: () => void;
    editingUser: User | null;
    fetchUsers: () => void;
}

interface User {
    _id?: string;
    email: string;
    fullName: string;
    linkedinUrl: string;
    environments: { name: string; url: string }[];
    password?: string;
}

const EditCreateUserModal: React.FC<Props> = ({
                                                  showModal,
                                                  closeModal,
                                                  editingUser,
                                                  fetchUsers,
                                              }) => {
    const [newUser, setNewUser] = useState<User>({
        email: '',
        fullName: '',
        linkedinUrl: '',
        environments: [],
        password: '',
    });

    const [environmentOptions, setEnvironmentOptions] = useState<{
        label: string;
        value: string;
    }[]>([]);

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchEnvironments();
        if (editingUser) {
            setNewUser({ ...editingUser, password: undefined });
        } else {
            setNewUser({
                email: '',
                fullName: '',
                linkedinUrl: '',
                environments: [],
                password: '',
            });
        }
    }, [editingUser]);

    const fetchEnvironments = async () => {
        try {
            const response = await api.get<Environment[]>(
                `${apiUrl}/admin/environments/list`
            );
            const options = response.data.map((env) => ({
                label: env.name,
                value: env.url,
            }));
            setEnvironmentOptions(options);
        } catch (error) {
            console.error('Error fetching environments:', error);
        }
    };

    const saveUser = async () => {
        try {
            setLoading(true);
            if (editingUser && !newUser.email) {
                setError('Email is required.');
                setLoading(false);
                return;
            }

            if (!editingUser && (!newUser.email || !newUser.password)) {
                setError('Email and password are required.');
                setLoading(false);
                return;
            }

            setError('');
            if (editingUser) {
                await api.put(`${apiUrl}/admin/users/${editingUser._id}`, newUser);
            } else {
                await api.post(`${apiUrl}/admin/users`, newUser);
            }
            fetchUsers();
            closeModal();
        } catch (error: any) {
            console.log('error', error)
            if (error.response && error.response.status === 400) {
                setError(error?.response?.data?.message || 'Unexpected network error');
            } else {
                setError('Error saving user.');
            }
            console.error('Error saving user:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEnvSelect = (values: OptionType | OptionType[]) => {
        setNewUser({
            ...newUser,
            environments: (values as OptionType[]).map((e: OptionType) => ({
                name: e.label,
                url: e.value,
            })),
            password: undefined
        });
    };

    return (
        <>
            {showModal && (
                <div
                    className="modal"
                    style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {editingUser ? 'Edit User' : 'Create User'}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={closeModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Email"
                                    value={newUser.email}
                                    onChange={(e) =>
                                        setNewUser({ ...newUser, email: e.target.value })
                                    }
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Full Name"
                                    value={newUser.fullName}
                                    onChange={(e) =>
                                        setNewUser({ ...newUser, fullName: e.target.value })
                                    }
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Linkedin URL"
                                    value={newUser.linkedinUrl}
                                    onChange={(e) =>
                                        setNewUser({ ...newUser, linkedinUrl: e.target.value })
                                    }
                                />
                                <DropdownWithCustom
                                    label="Environments"
                                    options={environmentOptions}
                                    multiply={true}
                                    onSelectionChange={handleEnvSelect}
                                    selectedValues={
                                        editingUser
                                            ? editingUser.environments.map((e) => ({
                                                label: e.name,
                                                value: e.url,
                                            }))
                                            : []
                                    }
                                />
                                <input
                                    type="password"
                                    className="form-control mb-2"
                                    placeholder="Password"
                                    value={newUser.password}
                                    onChange={(e) =>
                                        setNewUser({ ...newUser, password: e.target.value })
                                    }
                                />
                                {error && <div className="text-danger">{error}</div>}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={saveUser}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : editingUser ? 'Save' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditCreateUserModal;
