import React, { useState, useEffect } from 'react';
import { apiUrl } from '../config';
import api from '../api';
import {Environment} from "./admin/EnvironmentManagement";

const Onboarding: React.FC = () => {
    const [fullName, setFullName] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [error, setError] = useState('');
    const [isAuthenticatedWithMultiplyEnvironments, setIsAuthenticatedWithMultiplyEnvironments] = useState<Environment[]>();
    const [isAuthenticatedWithOneEnvironment, setIsAuthenticatedWithOneEnvironment] = useState<Environment>()
    const [isProfileUpdated, setIsProfileUpdated] = useState(false)

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await api.get(`${apiUrl}/v1/auth/token-is-valid`, { withCredentials: true });
                if (response.status === 200 && response.data.environments) {
                    const {environments, isOnboardingFinished} = response.data;
                    if (environments.length === 1) {
                        setIsAuthenticatedWithOneEnvironment(environments[0])
                    } else if (environments.length > 1) {
                        setIsAuthenticatedWithMultiplyEnvironments(environments)
                    } else {
                        setError('You have not access to any environment. Contact atad help.')
                    }

                    if (isOnboardingFinished) {
                        setIsProfileUpdated(true)
                        if (environments.length === 1) {
                            window.location.href = `${environments[0].url}`
                        }
                    }
                } else {
                    window.location.href = "/login"

                }
            } catch (error) {
                console.error('Error checking token:', error);
                setError('Error checking token');
            }
        };

        checkToken();
    }, []);

    const handleNext = async (e: any) => {
        e.preventDefault()

        if (!fullName || !linkedinUrl) {
            setError('Full Name and Linkedin URL are required.');
            return;
        }
        setError('');
        try {
            const response = await api.put(`${apiUrl}/v1/auth/update`, {
                fullName,
                linkedinUrl,
                isOnboardingFinished: true
            }, { withCredentials: true });

            if (response.status === 200) {
                setIsProfileUpdated(true)
                if (isAuthenticatedWithOneEnvironment) {
                    // TODO add logic to redirect to welcome page
                    window.location.href = `${isAuthenticatedWithOneEnvironment.url}/?welcome`
                }
            } else {
                setError('Error updating user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setError('Some unexpected network error');
        }
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">Onboarding</h2>
                    {!isProfileUpdated && <form onSubmit={handleNext}>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Linkedin URL" value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block mt-3">Next</button>
                    </form>}
                    {isProfileUpdated && isAuthenticatedWithMultiplyEnvironments && <div>
                        <p>Profile is updated! Now you have access to:</p>
                        {isAuthenticatedWithMultiplyEnvironments.map((env: any) =>  <button type="button" className="btn btn-primary btn-block" onClick={() => window.location.href = `${env.url}/?welcome`}>Go to {env.name}</button>)}
                    </div>}
                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default Onboarding;
