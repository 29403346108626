import React, { useState } from 'react';
import api, {setAuthToken} from "../../api";


interface LoginComponentProps {
    setAuthenticated: (value: boolean) => void;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ setAuthenticated }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await api.post('/admin/login', { password });
            const { token } = response.data;
            localStorage.setItem('token', token);
            setAuthToken(token);
            setAuthenticated(true);
        } catch (error) {
            setError('Invalid email or password');
        }
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                    </form>
                    {error && <div className="alert alert-danger mt-3">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
