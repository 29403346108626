// Layout.js

import React from 'react';
import logo from './media/logo.png';

const Layout = ({ children }: any) => {
    return (
        <div className="layout">
            <header className="header">
                <img src={logo} alt="Logo" className="logo" />
            </header>
            <main className="main">{children}</main>
        </div>
    );
};

export default Layout;
