import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './media/index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './layout';
import Admin from "./views/admin/Admin";
import UserLogin from "./views/UserLogin";
import Onboarding from "./views/Onboarding";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Layout>
                <div className="app-container">
                    <Switch>
                        <Route exact path="/" component={UserLogin} />
                        <Route path="/onboarding" component={Onboarding} />
                        <Route path="/admin" component={Admin} />
                    </Switch>
                </div>
            </Layout>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
